@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background-color: #242930;
}
.ant-input-number-outlined {
  border: none !important;
}
/* 滚动条宽度 */
::-webkit-scrollbar {
  width: 8px; /* 滚动条的宽度 */
  height: 8px; /* 水平滚动条的高度 */
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background: #2c2f36; /* 轨道背景色 */
  border-radius: 10px; /* 圆角 */
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #616161; /* 滑块颜色 */
  border-radius: 10px; /* 圆角 */
  border: 2px solid #2c2f36; /* 滑块边框 */
}

/* 滚动条滑块在悬停状态下的样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #828282; /* 滑块悬停时的颜色 */
}
